<template>
  <ProductFacetFilterMobile
    v-if="$vuetify.breakpoint.xs"
    :facets="facets"
    :filteredProductsCount="filteredProductsCount"
    :hideFilterString="hideFilterString"
    :totItems="totItems"
  />

  <ProductFacetFilterDesktop
    v-else
    :facets="facets"
    :hideFilterString="hideFilterString"
    :filteredProductsCount="filteredProductsCount"
    :totItems="totItems"
    :productsQty="productsQty"
  />
</template>

<script>
import ProductFacetFilterMobile from "@/components/product/filters/ProductFacetFilterMobile.vue";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import ProductFacetFilterDesktop from "@/components/product/filters/ProductFacetFilterDesktop.vue";
export default {
  name: "ProductFilters",
  components: {
    ProductFacetFilterMobile,
    ProductFacetFilterDesktop,
    // eslint-disable-next-line vue/no-unused-components
    ProductSortFilter
  },
  props: {
    facets: { type: Array },
    filteredProductsCount: { type: Number },
    hidePromoFilter: { type: Boolean, default: false },
    hideFilterString: { type: String, required: false },
    hideSort: { type: Boolean, default: false },
    productsQty: { type: Number },
    totItems: { type: Number }
  }
};
</script>

<style></style>
