<template>
  <v-row
    class="product-facer-filter-desktop"
    align="center"
    justify="space-between"
  >
    <template>
      <v-col cols="12" sm="9" md="8" class="d-flex align-center ">
        <transition>
          <v-btn
            v-if="hasFacets"
            link
            class="productFiltersBtn text-capitalize rounded-sm px-2"
            outlined
            elevation="0"
            :disabled="!facets"
            @click="menu = !menu"
            color="primary"
          >
            <span class="mr-8">{{
              $tc("filter.filters", getFacetsLength)
            }}</span>
            <v-spacer />
            <v-icon>$expand</v-icon>
          </v-btn>
        </transition>
        <transition>
          <v-chip
            v-if="getFacetsLength > 0"
            class="ml-2"
            outlined
            label
            @click="clearAllFilters"
          >
            {{ $t("filter.clear") }}
            <v-icon small class="ml-2">icon-close</v-icon>
          </v-chip>
        </transition>
      </v-col>
    </template>
    <v-col cols="4" sm="3" md="4" class="d-flex align-center justify-end">
      <transition>
        <ProductSortFilter v-if="filteredProductsCount > 4" />
      </transition>
    </v-col>
    <transition-expand>
      <v-col cols="12" v-if="menu">
        <v-divider />
        <div class="d-flex  justify-space-between white pa-3">
          <div
            v-for="facet in facetsFiltered"
            :key="facet.name"
            class="px-3 w-100"
          >
            <h5 class="primary--text font-weight-bold">
              {{ facet.name }}
              <span
                class="ml-2 text-caption"
                v-if="
                  facetsFilter[facet.parameterName] &&
                    facetsFilter[facet.parameterName].length > 0
                "
              >
                {{
                  $tc(
                    "filter.filters",
                    facetsFilter[facet.parameterName].length
                  )
                }}
              </span>
            </h5>
            <filtered-checkbox-list
              :facet="facet"
              :facetsFilter="facetsFilter"
              :filteredProductsQty="productsQty"
              @handleFacetFilter="handleFacetFilter"
            />
          </div>
        </div>
        <v-row no-gutters justify="end" class="mb-4 px-4">
          <v-btn color="primary" @click="menu = false">
            Applica
          </v-btn>
        </v-row>
        <v-divider />
      </v-col>
    </transition-expand>
  </v-row>
</template>
<style lang="scss">
.product-facer-filter-desktop {
  .v-input--selection-controls {
    margin-top: 0;
  }
  .mobileFilters {
    border-color: $gray-border-color;
    min-width: 240px;
    .v-btn__content {
      span {
        font-size: 14px;
        color: $text-color;
      }
    }
  }
  .v-input--checkbox {
    .v-input--selection-controls__input {
      margin-right: 0px !important;
    }
    .v-icon {
      font-size: 26px;
    }
  }
  .v-menu__content {
    background-color: $white;
  }
}
</style>
<script>
import { mapGetters } from "vuex";
import FilteredCheckboxList from "./FilteredCheckboxList.vue";
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import cloneDeep from "lodash/cloneDeep";
import TransitionExpand from "@/components/TransitionExpand.vue";
export default {
  name: "ProductFacetFilterMobile",
  components: { FilteredCheckboxList, ProductSortFilter, TransitionExpand },
  data() {
    return {
      dialog: false,
      panel: [],
      loadingApplyButton: false,
      initialFacets: {},
      fav: true,
      menu: false,
      message: false,
      hints: true
    };
  },
  props: {
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },
    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" },
    productsQty: { type: Number },
    totItems: { type: Number }
  },
  computed: {
    ...mapGetters({
      storedPromoFilter: "category/promoFilter",
      storedNewFilter: "category/newFilter"
    }),
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set(value) {
        this.$store.dispatch("category/setFilterFacetsMobile", {
          facets: value
        });
        global.EventBus.$emit("filterChanged");
      }
    },
    promoFilter: {
      get() {
        return this.storedPromoFilter;
      },
      set(promo) {
        this.$store.dispatch("category/setFilterPromo", promo);
        global.EventBus.$emit("filterChanged");
      }
    },
    newFilter: {
      get() {
        return this.storedNewFilter;
      },
      set(isNew) {
        this.$store.dispatch("category/setFilterNew", isNew);
        global.EventBus.$emit("filterChanged");
      }
    },
    hasFacets() {
      return this.facets.length > 0;
    },
    getFacetsLength() {
      // const reducedFacets = Object.keys(this.selectedFacetsXS).reduce
      let facetsLength = 0;
      for (const prop in this.facetsFilter) {
        facetsLength += this.facetsFilter[prop].length;
      }
      return facetsLength;
    },
    // selectedFacetsXS() {
    //   return cloneDeep(this.facetsFilter);
    // },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    }
  },
  methods: {
    handleFacetFilter(value) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: value
      });
      global.EventBus.$emit("filterChanged");
    },
    applyFilters() {
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = cloneDeep(this.facetsFilter);
      this.dialog = false;
      this.panel = [];
    },
    clearAllFilters() {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: {}
      });
      global.EventBus.$emit("filterChanged");
      this.initialFacets = {};
      this.dialog = false;
      this.panel = [];
    },
    discard() {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: this.initialFacets
      });
      global.EventBus.$emit("filterChanged");
      this.dialog = false;
      this.panel = [];
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>
