<template>
  <div class="grey lighten-2 pt-0">
    <v-row align="center" justify="space-between">
      <v-col cols="4">
        <v-btn
          class="productFiltersBtn text-capitalize rounded-sm px-2"
          text
          @click="dialog = true"
        >
          <v-icon color="primary">$filter</v-icon>
          <span> {{ $tc("filter.filters", getFacetsLength) }}</span>
        </v-btn>
      </v-col>
      <v-col cols="3 text-center"
        ><span class="text-nowrap text-caption"
          >{{ totItems }} prodotti</span
        ></v-col
      >
      <v-col cols="5">
        <ProductSortFilter
          class="text-capitalize rounded-sm font-weight-bold text-body-2"
          :sortFilter="sortFilter"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      hide-overlay
      fullscreen
      class="facet-dialog"
    >
      <div class="product-facet-filter-mobile-container">
        <v-row class="top-bar d-flex justify-end align-center">
          <v-btn
            plain
            small
            @click="discard()"
            class="my-2 text-capitalize discard"
          >
            <span>{{ $t("filter.button.discard") }}</span>
            <v-icon small class="ml-1 filter-close">$close</v-icon>
          </v-btn>
        </v-row>
        <div class="list">
          <!-- <v-row
          no-gutters
          class="d-flex pl-1 mb-2 filter-row"
          justify="space-between"
          align="center"
        >
          <span class="font-weight-bold"
            ><v-icon color="promo" class="mr-3">$percent</v-icon
            >{{ $t("filter.promo") }}</span
          >
          <v-switch
            :input-value="promoFilter"
            inset
            dense
            @change="handlePromoFilter"
            hide-details
            class="my-0"
            color="primary"
          >
          </v-switch>
        </v-row> -->

          <v-expansion-panels multiple flat v-model="panel">
            <v-expansion-panel
              v-for="facet in facetsFiltered"
              :key="facet.name"
            >
              <v-expansion-panel-header>
                <span class="text-body-2 font-weight-bold">{{
                  facet.name
                }}</span>
                <div
                  class="ml-2 text-caption"
                  v-if="
                    facetsFilter[facet.parameterName] &&
                      facetsFilter[facet.parameterName].length > 0
                  "
                >
                  {{
                    $tc(
                      "filter.filters",
                      facetsFilter[facet.parameterName].length
                    )
                  }}
                </div>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters class="d-flex flex-wrap">
                  <v-checkbox
                    class="pr-2 w-50 d-flex align-center"
                    multiple
                    hide-details
                    v-for="value in facet.values"
                    :key="value.id"
                    :value="value.id"
                    :disabled="value.filteredCount == 0"
                    v-model="facetsFilter[facet.parameterName]"
                    :label="value.name + ' (' + value.filteredCount + ')'"
                    @click="handleFacetFilter"
                  ></v-checkbox>
                  <!-- <filtered-checkbox-list
                  :facet="facet"
                  :facetsFilter="facetsFilter[facet.parameterName]"
                  :filteredProductsQty="productsQty"
                /> -->
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="d-flex pa-2 bottom-bar justify-space-between">
          <v-btn
            color="primary"
            class="w-45"
            v-on:click="applyFilters"
            depressed
            :loading="loadingApplyButton"
          >
            {{
              $tc("filter.button.applyShowAllProducts", filteredProductsCount)
            }}
          </v-btn>
          <v-btn
            v-on:click="clearAllFilters"
            depressed
            class="filter-button w-45"
          >
            {{ $t("filter.button.reset") }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.product-facet-filter-mobile-container {
  $buttons-row-height: 60px;
  .top-bar {
    height: $buttons-row-height;
    .discard {
      font-size: 0.9rem !important;
    }
    .filter-close {
      font-size: 18px !important;
    }
  }
  .bottom-bar {
    height: $buttons-row-height;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .list {
    height: calc(100vh - (#{$buttons-row-height} * 2));
    overflow-y: auto;
    .filter-row {
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        line-height: 3;
      }
    }
  }
  .v-expansion-panel-header {
    padding-top: 10px;
    padding-bottom: 5px;
    min-height: 48px;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 15px;
  }
}
</style>
<script>
import ProductSortFilter from "@/components/product/filters/ProductSortFilter.vue";
import cloneDeep from "lodash/cloneDeep";
// import FilteredCheckboxList from "./FilteredCheckboxList.vue";
export default {
  name: "ProductFacetFilterMobile",
  data() {
    return {
      dialog: false,
      panel: [],
      loadingApplyButton: false,
      initialFacets: {}
    };
  },
  components: {
    ProductSortFilter
    // FilteredCheckboxList
  },
  props: {
    facets: {
      type: Array,
      default: () => {
        return [];
      }
    },

    filteredProductsCount: { type: Number, required: false },
    hideFilterString: { type: String, default: "" },
    sortFilter: { type: Object },
    totItems: { type: Number }
  },
  computed: {
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set(value) {
        //in questo caso non viene mai chiamato per il la checkbox p puntata a dei valori dell'array.
        this.$store.dispatch("category/setFilterFacetsMobile", {
          facets: value
        });
        global.EventBus.$emit("filterChanged");
      }
    },
    promoFilter: {
      get() {
        return this.storedPromoFilter;
      },
      set(promo) {
        this.$store.dispatch("category/setFilterPromo", promo);
        global.EventBus.$emit("filterChanged");
      }
    },
    newFilter: {
      get() {
        return this.storedNewFilter;
      },
      set(isNew) {
        this.$store.dispatch("category/setFilterNew", isNew);
        global.EventBus.$emit("filterChanged");
      }
    },
    getFacetsLength() {
      // const reducedFacets = Object.keys(this.selectedFacetsXS).reduce
      let facetsLength = 0;
      for (const prop in this.selectedFacetsXS) {
        facetsLength += this.selectedFacetsXS[prop].length;
      }
      return facetsLength;
    },
    // selectedFacetsXS() {
    //   return cloneDeep(this.facetsFilter);
    // },
    facetsFiltered() {
      let vm = this;
      return vm.facets.filter(
        item => vm.hideFilterString.indexOf(item.name) < 0
      );
    }
  },
  methods: {
    handleFacetFilter() {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: this.facetsFilter
      });
      global.EventBus.$emit("filterChanged");
    },
    applyFilters() {
      console.log(this.selectedFacetsXS);
      // memorizing last filters applied for future use in case of discard()
      this.initialFacets = cloneDeep(this.selectedFacetsXS);
      this.dialog = false;
      this.panel = [];
    },
    clearAllFilters() {
      this.$emit("handleFacetFilterMobile", {});
      this.initialFacets = {};
      this.dialog = false;
      this.panel = [];
    },
    discard() {
      this.$emit("handleFacetFilterMobile", this.initialFacets);
      this.dialog = false;
      this.panel = [];
    }
  },
  watch: {
    // retrieves filtered products quantity and sets text for Apply Button
    filteredProductsCount(val, oldVal) {
      if (val != oldVal) {
        this.loadingApplyButton = false;
      }
    }
  }
};
</script>
